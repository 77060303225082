const shuffleNumberCounter = (target) => {
  const targetNum = Number(target.getAttribute("data-num"));

  if (!targetNum) {
    return;
  }

  let counterData = null;
  const speed = 10 / targetNum;
  let initNum = 0;

  const countUp = () => {
    if (Number.isInteger(targetNum)) {
      target.innerHTML = initNum;
    } else {
      target.innerHTML = `${initNum}.${Math.floor(Math.random() * 9)}`;
    }

    initNum++;

    if (initNum > targetNum) {
      target.innerHTML = targetNum;
      clearInterval(counterData);
    }
  };

  counterData = setInterval(countUp, speed);
};

const target = document.querySelectorAll(".js-countup");

const options = {
  root: null,
  rootMargin: "0px 0px",
  threshold: 0,
};
const observer = new IntersectionObserver(doWhenIntersect, options);
target.forEach((box) => {
  observer.observe(box);
});

function doWhenIntersect(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      shuffleNumberCounter(entry.target);
      observer.unobserve(entry.target);
    }
  });
}
