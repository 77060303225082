const lecturers = document.querySelectorAll(".js-lecturer");
const popup = document.querySelector(".lecturer-popup");
const popuInner = document.querySelector(".lecturer-popup__inner");
const overlay = document.querySelector(".lecturer-overlay");

// overlayクリックで非表示
function hidden() {
  overlay.style.display = "none";
  popup.style.display = "none";
  popup.dataset.status = "deactive";
  document.querySelector("html").style.overflow = "auto";
}

function delay() {
  closeIcon = document.querySelector(".c-lecturers-modal__close"); // closeIcon を取得
  closeIcon.addEventListener("click", () => {
    hidden();
  });
}

overlay.addEventListener("click", () => {
  hidden();
});

lecturers.forEach((lecturer) => {
  lecturer.addEventListener("click", () => {
    // popupがアクティブかどうか判定しスタイル変更
    if (popup.dataset.status === "deactive") {
      overlay.style.display = "block";
      popup.style.display = "block";
      popup.dataset.status = "active";
      document.querySelector("html").style.overflow = "hidden";
    } else {
      overlay.style.display = "none";
      popup.style.display = "none";
      popup.dataset.status = "deactive";
      popup.innerHTML = "";
      document.querySelector("html").style.overflow = "auto";
    }

    // 非表示
    delay();

    // // ローディング中
    let loading = true;

    // クリックした投稿のID取得
    const postId = lecturer.dataset.lecturer;

    // Rest API処理
    // テストサーバーの時のみ
    // /wp-json/wp/v2/lecturers/${postId}?_embed&acf_format=standard
    fetch(
      `https://www.agoglobal.co.jp/wp-json/wp/v2/lecturers/${postId}?_embed&acf_format=standard`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // popupの中身を作成
        let html = "";
        const title = data.title.rendered;
        image = data._embedded["wp:featuredmedia"]
          ? data._embedded["wp:featuredmedia"][0].source_url
          : null;
        const language = data.meta._locale;
        const nationWide =
          data.acf["lecturers-checkbox"].length > 0
            ? language == "en_US"
              ? "Nationwide Support"
              : "全国対応講師"
            : "";
        const area = data.acf["lecturers-area"];
        const content = data.content.rendered;

        html += `
        <article class="c-lecturers-modal">
        <div class="c-lecturers-modal__inner">
            <div class="c-lecturers-modal__img">${
              image
                ? `<img src=${image} alt="*"/>`
                : `<img src="${path}/img/thumbnail.jpg" alt="サムネイル">`
            }</div>
              <div class="c-lecturers-modal__body">
                  <div class="c-single__head">
                      <h3 class="c-single__title--therapists">${title}</h3>
                      <div class="c-therapists-info">
                        <p class="c-therapists-category">${area}</p>
                        ${
                          nationWide &&
                          `<p class="c-therapists-batch">${nationWide}</p>`
                        }                      
                      </div>
                  </div>
                  <div class="c-single__content">${content}</div>
              </div>
              </div>
        </article>
        `;

        // 中身作成後ローディング非表示
        loading = false;

        // popupの中身を作成したものに置き換える
        popuInner.innerHTML = html;
      });

    if (loading) {
      // ローディング中の表示
      popuInner.innerHTML = `<div class="loading"></div>`;
    }
  });
});
