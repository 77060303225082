const boxes = document.querySelectorAll(".js-inview");

const options = {
  root: null,
  rootMargin: "0px 0px",
  threshold: 0,
};
const observer = new IntersectionObserver(doWhenIntersect, options);
boxes.forEach((box) => {
  observer.observe(box);
});

function doWhenIntersect(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      setTimeout(() => {
        entry.target.classList.add("is-inview");
      }, 750);
    }
  });
}
