// Swiper
import Swiper, { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/css/bundle";
Swiper.use([Navigation, Pagination, Autoplay]);

const numberOfSlides = document.querySelectorAll(".swiper-slide").length;
const breakpoint = 768;

const shouldLoop =
  window.innerWidth > breakpoint ? numberOfSlides >= 4 : numberOfSlides >= 2;

const swiper = new Swiper(".lecturers-swiper", {
  loop: true,
  autoplay: true,
  spaceBetween: 16,
  centeredSlides: true,
  speed: 1000,
  autoplay: {
    disableOnInteraction: false,
    delay: 3000, // 1.5秒後に次のスライド
  },
  breakpoints: {
    320: {
      slidesPerView: 1.3,
    },
    460: {
      slidesPerView: 2,
    },
    600: {
      slidesPerView: 2,
    },
    937: {
      slidesPerView: 3.75,
    },
  },

  pagination: {
    el: ".swiper-pagination-lecturers",
    type: "fraction",

    formatFractionTotal: function (number) {
      return ("0" + number).slice(-2);
    },
  },

  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: true,
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
